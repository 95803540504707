
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TeacherUserItem from "./TeacherUserItem.vue";
import { CourseStudentsType } from "../types/course/course-type";
import { TypeUserListEnum } from "../types/enum/type-user-item";
import ManagementUserItem from "./ManagementUserItem.vue";

@Options({
  components: {
    TeacherUserItem,
    ManagementUserItem,
  },
})
export default class UserCard extends Vue {
  @Prop() readonly student_list!: Array<CourseStudentsType>;
  @Prop() readonly type!: TypeUserListEnum;

  private type_teacher: TypeUserListEnum = TypeUserListEnum.TEACHER;
  private type_user_management: TypeUserListEnum = TypeUserListEnum.MANAGEMENT;
}
