import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-6 py-3 flex" }
const _hoisted_2 = { class: "font-medium text-gray-900 pr-10 flex-1 text-left" }
const _hoisted_3 = { class: "flex whitespace-nowrap text-sm text-gray-500 flex-1" }
const _hoisted_4 = { class: "mr-2" }
const _hoisted_5 = { class: "flex whitespace-nowrap text-sm text-gray-500 flex-1" }
const _hoisted_6 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.student.username), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.student._id), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.student.enrollment_status), 1)
    ]),
    (!(_ctx.student.enrollment_status === _ctx.type_enrolled))
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleEnrollement(_ctx.type_enrolled))),
          class: "text-indigo-600 hover:text-indigo-900"
        }, " Schválit "))
      : _createCommentVNode("", true),
    (!(_ctx.student.enrollment_status === _ctx.type_denied))
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleEnrollement(_ctx.type_declined))),
          class: "ml-5 text-indigo-600 hover:text-indigo-900"
        }, " Zamítnout "))
      : _createCommentVNode("", true)
  ]))
}