import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_2 = { class: "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" }
const _hoisted_3 = { class: "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" }
const _hoisted_4 = { class: "container w-full text-center bg-white border-t-0 rounded-xl rounded-t-none" }
const _hoisted_5 = { class: "min-w-full divide-y divide-gray-200" }
const _hoisted_6 = { class: "whitespace-nowrap" }
const _hoisted_7 = { class: "divide-y divide-gray-200" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_teacher_user_item = _resolveComponent("teacher-user-item")!
  const _component_management_user_item = _resolveComponent("management-user-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("ul", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.type === _ctx.type_teacher)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.student_list, (student) => {
                          return (_openBlock(), _createBlock(_component_teacher_user_item, {
                            key: student._id,
                            student: student
                          }, null, 8, ["student"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.type === _ctx.type_user_management)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.student_list, (student) => {
                          return (_openBlock(), _createBlock(_component_management_user_item, {
                            key: student._id,
                            student: student
                          }, null, 8, ["student"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}