import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { request } from "@/requests/api-request";
import { UserListType, LevelPayloadType } from "@/types/user/user-list-type";
import { AxiosError } from "axios";
import { getHeaderAuth } from "../handlers-helpers";

export async function userListAllHandler(): Promise<void | Array<UserListType>> {
  return await request(undefined, "GET", `user/all`, undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Nejsi přihlášen", "Tuto operaci nemůžeš provést bez přihlášení. Prosím přihlaš se", AlertSeverities.fatal);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function userPatchHandler(payload: LevelPayloadType, user_id: number): Promise<void> {
  return await request(undefined, "PATCH", `user/${user_id}`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        showAlert("Úspěšně změněna role uživatele!", "Vše jsme si poznamenali a uživatel je změněn", AlertSeverities.success);
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Nejsi přihlášen", "Tuto operaci nemůžeš provést bez přihlášení. Prosím přihlaš se.", AlertSeverities.fatal);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
        case 404:
          showAlert("Uživatel neexistuje", "Oprace nad daným neexistujícím uživatelem.", AlertSeverities.fatal);
          break;
      }
    });
}
