
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CourseType } from "../types/course/course-type";
import { LevelEnum } from "../types/enum/level-enum";
import { userPatchHandler } from "../handlers/user/user-account-handlers";
import { LevelPayloadType } from "../types/user/user-list-type";
import { isLoggedIn } from "../handlers/handlers-helpers";
import { AlertSeverities, showAlert } from "@/helpers/alerts";

@Options({
  components: {},
})
export default class ManagementUserItem extends Vue {
  @Prop() readonly student!: CourseType;

  private type_user: LevelEnum = LevelEnum.USER;
  private type_mod: LevelEnum = LevelEnum.MOD;
  private type_admin: LevelEnum = LevelEnum.ADMIN;

  async handleChangeAdmin(level: LevelEnum): Promise<void> {
    if (isLoggedIn()) {
      const payload: LevelPayloadType = {
        level: level,
      };
      const response = await userPatchHandler(payload, this.student._id);
      if (response === undefined) return;
      switch (level) {
        case LevelEnum.USER:
          showAlert("Úspěch", "Práva uživateli úspěšně změněna na základní", AlertSeverities.success);
          break;
        case LevelEnum.MOD:
          showAlert("Úspěch", "Práva uživateli úspěšně změněna na moderátorské", AlertSeverities.success);
          break;
        case LevelEnum.ADMIN:
          showAlert("Úspěch", "Práva uživateli úspěšně změněna na administrátorské", AlertSeverities.success);
          break;
      }
      this.$router.go(0);
    }
  }
}
