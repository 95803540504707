
import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { courseEnrollHandler } from "../handlers/course/course-teachers-handlers";
import { isLoggedIn } from "../handlers/handlers-helpers";
import { CourseEnrollStudentFormType, CourseType } from "../types/course/course-type";
import { EnrollmentEnum } from "../types/enum/enrollment-enum";

@Options({
  components: {},
})
export default class TeacherUserItem extends Vue {
  @Prop() readonly student!: CourseType;

  private type_denied: EnrollmentEnum = EnrollmentEnum.DENIED;
  private type_enrolled: EnrollmentEnum = EnrollmentEnum.ENROLLED;

  async handleEnrollement(enrollment: EnrollmentEnum): Promise<void> {
    if (isLoggedIn()) {
      const payload: CourseEnrollStudentFormType = {
        id: this.student._id,
        enrollment_status: enrollment,
      };
      const response = await courseEnrollHandler(payload, parseInt(this.$route.params.course_id.toString(), 10));
      if (response === undefined) return;
      if (enrollment === EnrollmentEnum.ENROLLED) {
        showAlert("Úspěch", "Žák úspěšně potvrzen.", AlertSeverities.success);
      } else {
        showAlert("Úspěch", "Žák úspěšně zamítnut.", AlertSeverities.success);
      }
      this.$router.go(0);
    }
  }
}
